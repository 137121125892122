var ft = Object.defineProperty;
var ht = (t, e, r) => e in t ? ft(t, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : t[e] = r;
var y = (t, e, r) => (ht(t, typeof e != "symbol" ? e + "" : e, r), r), be = (t, e, r) => {
  if (!e.has(t))
    throw TypeError("Cannot " + r);
};
var c = (t, e, r) => (be(t, e, "read from private field"), r ? r.call(t) : e.get(t)), p = (t, e, r) => {
  if (e.has(t))
    throw TypeError("Cannot add the same private member more than once");
  e instanceof WeakSet ? e.add(t) : e.set(t, r);
}, g = (t, e, r, n) => (be(t, e, "write to private field"), n ? n.call(t, r) : e.set(t, r), r);
var Ie = (t, e, r, n) => ({
  set _(i) {
    g(t, e, i, r);
  },
  get _() {
    return c(t, e, n);
  }
}), w = (t, e, r) => (be(t, e, "access private method"), r);
import pt from "lodash.debounce";
class P extends Error {
  constructor(r, n, i) {
    super(r);
    y(this, "_context", null);
    y(this, "_isCritical", !1);
    if (this.name = "CgError", n && (this._context = n), i && typeof i == "object") {
      const { cause: o, critical: s } = i;
      o instanceof Error && (this.cause = o), typeof s == "boolean" && (this._isCritical = s);
    }
  }
  isCritical() {
    return this._isCritical;
  }
  toString() {
    let r = `${this._isCritical ? "[CRITICAL] " : ""}[${this.name}] ${this.message}`;
    return this._context && (r += ` Context: ${JSON.stringify(this._context)}`), this.cause && (r += ` Cause: [${this.cause.toString()}]`), r;
  }
}
class yt {
  constructor() {
    y(this, "params", {});
  }
  set(e, r) {
    this.params[e] = r;
  }
  toString() {
    return Object.keys(this.params).map((e) => `${encodeURIComponent(e)}=${encodeURIComponent(this.params[e])}`).join("&");
  }
}
function wt(t) {
  return new Promise((e) => {
    const r = setTimeout(() => {
      e(r);
    }, t);
  });
}
function Me(t, e, r = !1, n = null) {
  const i = Number.parseFloat(t), o = (s) => {
    if (r) {
      const a = Math.floor(s);
      return Math.max(a, e);
    }
    return s;
  };
  return Number.isNaN(i) ? o(n !== null ? Math.max(n, e) : e) : o(Math.max(i, e));
}
function Je(t, e, r = !1, n = null) {
  const i = Number.parseFloat(t), o = (s) => {
    if (r) {
      const a = Math.ceil(s);
      return Math.min(a, e);
    }
    return s;
  };
  return Number.isNaN(i) ? o(n !== null ? Math.min(n, e) : e) : o(Math.min(i, e));
}
function I(t, e, r, n = !1, i = !1, o = "lower") {
  let s = null;
  switch (o) {
    case "upper":
      s = r;
      break;
    case "lower":
      s = e;
      break;
    default:
      typeof o == "number" && (s = o);
  }
  const a = Me(t, e, i, s);
  return Je(a, r, n, s);
}
function gt() {
  let t = !1;
  try {
    t = window.navigator.javaEnabled();
  } catch {
  }
  return {
    language: window.navigator.language,
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    timezone: (/* @__PURE__ */ new Date()).getTimezoneOffset(),
    javaEnabled: t,
    javascriptEnabled: !0
  };
}
function mt(t, e = St()) {
  typeof e.append == "function" ? e.append(t) : e.appendChild(t);
}
function St() {
  return document.body || document.querySelector("body") || document.documentElement;
}
function vt() {
  return typeof TextEncoder < "u" ? new TextEncoder() : {
    encode: (t) => {
      const e = [];
      for (let r = 0; r < t.length; r++) {
        let n = t.charCodeAt(r);
        n < 128 ? e.push(n) : n < 2048 ? (e.push(192 | n >> 6), e.push(128 | n & 63)) : (e.push(224 | n >> 12), e.push(128 | n >> 6 & 63), e.push(128 | n & 63));
      }
      return new Uint8Array(e);
    }
  };
}
function bt() {
  return typeof URLSearchParams < "u" ? new URLSearchParams() : new yt();
}
function Et(t = !1) {
  const e = {
    isSecureContext: window == null ? void 0 : window.isSecureContext,
    isSecureContextByHttps: window.location.protocol === "https:"
  }, r = ["vendor", "connection", "hardwareConcurrency", "cookieEnabled", "appCodeName", "appName", "appVersion", "platform", "product", "userAgent", "language", "onLine", "userAgentData", "globalPrivacyControl", "maxTouchPoints", "productSub", "userActivation", "webdriver", "doNotTrack"], n = navigator;
  for (const i of r)
    i in n && ![null, void 0].includes(n[i]) && (typeof n[i] == "object" ? Object.keys(n[i]).length > 0 && (e[i] = n[i]) : e[i] = n[i]);
  return t ? " Navigator: " + JSON.stringify(e) : e;
}
function qe() {
  return "randomUUID" in crypto ? crypto.randomUUID() : We([8, 4, 4, 4, 12]);
}
function We(t, e = "-") {
  return (Array.isArray(t) ? t : [t]).map((n) => {
    let i = "";
    do
      i += Math.floor(Math.random() * Date.now()).toString(32);
    while (i.length < n);
    return i.slice(0, n);
  }).join(e);
}
function Tt(t, e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz") {
  let r = "";
  const n = e.length;
  let i = 0;
  for (; i < t; )
    r += e.charAt(Math.floor(Math.random() * n)), i += 1;
  return r;
}
function xt(t, e = 1, r = 1 / 0) {
  if (e > r)
    throw new TypeError("Min length cannot be greater than max length.");
  if (typeof t == "string") {
    const n = t.trim().length;
    return n >= e && n <= r;
  }
  return !1;
}
function Rt(t) {
  return t == null || typeof t == "string" && t.trim() === "" || Ae(t) && t.length === 0 || U(t) && Object.keys(t).length === 0;
}
function Lt(t) {
  return E(t) && Se(t, "success") === !0;
}
function E(t) {
  return t != null;
}
function U(t) {
  return t !== null && typeof t == "object" && !Array.isArray(t);
}
function Ae(t) {
  return Array.isArray(t);
}
function H(t) {
  return typeof t == "function";
}
function re(t, e) {
  return !U(t) || typeof e != "string" ? void 0 : e.split(".").reduce((n, i) => {
    if ($(n, i))
      return n[i];
  }, t);
}
function Se(t, e, r = void 0) {
  return $(t, e) ? t[e] : r;
}
function At(t, e) {
  return !U(t) || typeof e != "string" ? !1 : e.split(".").every((r) => $(t, r));
}
function $(t, e) {
  return U(t) && e in t;
}
function b(t, e = "Unknown error") {
  if (E(t)) {
    if (t instanceof Error)
      return t.toString();
    if ($(t, "message"))
      return t.message;
    if (typeof t == "string")
      return t;
    if (U(t) || Ae(t))
      return JSON.stringify(t, Object.getOwnPropertyNames(t));
    if (H(t == null ? void 0 : t.toString))
      return t.toString();
  }
  return e;
}
async function Ot(t) {
  return new Promise((e, r) => {
    try {
      if (typeof t.foundValidator == "function" && t.foundValidator()) {
        e(!0);
        return;
      }
      if (document.getElementById(t.id))
        t.foundValidator ? typeof t.foundValidator != "function" ? (console.warn("FoundValidator is not a function"), e(!1)) : t.foundValidator() === !0 ? e(!0) : r("Script injected to the DOM but not loaded.") : e(!1);
      else {
        let i = null;
        if (t.elementType === "script")
          i = document.createElement("script"), i.src = t.link, i.async = t.async ?? !1, i.defer = t.defer ?? !1;
        else if (t.elementType === "link")
          i = document.createElement("link"), i.href = t.link, i.rel = t.rel, i.as = t.as;
        else
          throw new P("Invalid elementType: " + t.elementType);
        i.id = t.id, i.addEventListener("load", () => {
          !t.foundValidator || typeof t.foundValidator == "function" && t.foundValidator() === !0 ? e(!0) : e(!1);
        }), t.elementType !== "link" && i.addEventListener("error", () => {
          r(!1);
        }), document.head.append(i);
      }
    } catch {
      r(!1);
    }
  });
}
const Pt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  appendElement: mt,
  appendScript: Ot,
  bothBarrier: I,
  createURLSearchParams: bt,
  delayedPromise: wt,
  errorToString: b,
  getBrowserInfo: gt,
  getNavigatorDump: Et,
  getProperty: re,
  getPropertySave: Se,
  getPseudoRandom: We,
  getRandom: Tt,
  getRandomUuid: qe,
  getTextEncoder: vt,
  hasDirectProperty: $,
  hasProperty: At,
  isArray: Ae,
  isDefined: E,
  isEmpty: Rt,
  isFunction: H,
  isObject: U,
  isSuccess: Lt,
  lowerBarrier: Me,
  upperBarrier: Je,
  validateString: xt
}, Symbol.toStringTag, { value: "Module" }));
var de = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, Be = {}, x = de && de.__classPrivateFieldGet || function(t, e, r, n) {
  if (r === "a" && !n)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? t !== e || !n : !e.has(t))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return r === "m" ? n : r === "a" ? n.call(t) : n ? n.value : e.get(t);
}, Ee = de && de.__classPrivateFieldSet || function(t, e, r, n, i) {
  if (n === "m")
    throw new TypeError("Private method is not writable");
  if (n === "a" && !i)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof e == "function" ? t !== e || !i : !e.has(t))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return n === "a" ? i.call(t, r) : i ? i.value = r : e.set(t, r), r;
}, _, V;
Object.defineProperty(Be, "__esModule", { value: !0 });
class Nt {
  constructor() {
    _.set(this, !1), V.set(this, /* @__PURE__ */ new Set());
  }
  /**
   * Whether the lock is currently acquired or not. Accessing this property does not affect the
   * status of the lock.
   */
  get acquired() {
    return x(this, _, "f");
  }
  /**
   * Acquires the lock, waiting if necessary for it to become free if it is already locked. The
   * returned promise is fulfilled once the lock is acquired.
   *
   * A timeout (in milliseconds) may be optionally provided. If the lock cannot be acquired before
   * the timeout elapses, the returned promise is rejected with an error. The behavior of invalid
   * timeout values depends on how `setTimeout` handles those values.
   *
   * After acquiring the lock, you **must** call `release` when you are done with it.
   */
  acquireAsync({ timeout: e } = {}) {
    if (!x(this, _, "f"))
      return Ee(this, _, !0, "f"), Promise.resolve();
    if (e == null)
      return new Promise((i) => {
        x(this, V, "f").add(i);
      });
    let r, n;
    return Promise.race([
      new Promise((i) => {
        r = () => {
          clearTimeout(n), i();
        }, x(this, V, "f").add(r);
      }),
      new Promise((i, o) => {
        n = setTimeout(() => {
          x(this, V, "f").delete(r), o(new Error("Timed out waiting for lock"));
        }, e);
      })
    ]);
  }
  /**
   * Acquires the lock if it is free and otherwise returns immediately without waiting. Returns
   * `true` if the lock was free and is now acquired, and `false` otherwise.
   *
   * This method differs from calling `acquireAsync` with a zero-millisecond timeout in that it runs
   * synchronously without waiting for the JavaScript task queue.
   */
  tryAcquire() {
    return x(this, _, "f") ? !1 : (Ee(this, _, !0, "f"), !0);
  }
  /**
   * Releases the lock and gives it to the next waiting acquirer, if there is one. Each acquirer
   * must release the lock exactly once.
   */
  release() {
    if (!x(this, _, "f"))
      throw new Error("Cannot release an unacquired lock");
    if (x(this, V, "f").size > 0) {
      const [e] = x(this, V, "f");
      x(this, V, "f").delete(e), e();
    } else
      Ee(this, _, !1, "f");
  }
}
var Dt = Be.default = Nt;
_ = /* @__PURE__ */ new WeakMap(), V = /* @__PURE__ */ new WeakMap();
function Q(t, e) {
  try {
    return ze(ue(t, (e == null ? void 0 : e.ignore) ?? [], I(e == null ? void 0 : e.maxDeep, 1, 15, !0, !0, 5), (e == null ? void 0 : e.stringify) === !0, 0), (e == null ? void 0 : e.stringify) === !0, I(e == null ? void 0 : e.maxStringLength, 0, 3e4, !0, !0, 2e4), e == null ? void 0 : e.linePrefixSpace);
  } catch (r) {
    return "Error: " + ((r == null ? void 0 : r.toString()) ?? r.message);
  }
}
function ze(t, e = !1, r = 15e3, n = void 0) {
  return e ? typeof t != "string" ? JSON.stringify(t, null, n).slice(0, r) : String(t).slice(0, r) : t;
}
function ue(t, e, r, n, i) {
  if (t instanceof Date)
    return n ? t.toISOString() : t;
  if (Array.isArray(t))
    return t.map((o) => ue(o, e, r, n));
  if (typeof t == "object" && t !== null) {
    if (r <= 0)
      return "==deeper==";
    if ("data" in t && "status" in t && "success" in t && "headers" in t)
      return He(t);
    if (t instanceof Error) {
      const { name: a, message: u, stack: f, cause: d, context: l } = t, h = { name: a, message: u, stack: f, cause: d };
      return "context" in t && (h.context = l), ue(h, e, 3, n);
    }
    const o = ["client", "session", "token", "password", "parentElement", "parentNode", "children", "childNodes", "innerHTML", "outerHTML", "innerText", "ownerDocument", "offsetParent", "ownerElement", "googlepayClient", "window", "self", "document", "top", "parent", "state", "frames", "navigator", "doctype", "documentElement", "body", "head", "defaultView", "scripts", "scrollingElement", "firstElementChild", "lastElementChild", "activeElement", "styleSheets", "adoptedStyleSheets", "firstChild", "lastChild", "srcElement"], s = {};
    for (const a in t) {
      if (e.includes(a)) {
        s[a] = "==ignore==";
        continue;
      }
      if (o.includes(a)) {
        s[a] = "==skip==";
        continue;
      }
      s[a] = ue(t[a], e, r - 1, n);
    }
    return s;
  }
  return typeof t == "function" ? "func" : t;
}
function He(t) {
  try {
    if (!t || typeof t != "object")
      return {
        error: "Invalid response object",
        details: typeof t,
        value: t
      };
    const e = {};
    return t.config && typeof t.config == "object" && (e.base = t.config.baseUrl, e.url = t.config.url ?? t.url, e.body = t.config.body, e.params = t.config.params, e.method = t.config.method, e.timeout = t.config.timeout, e.headers = t.config.headers), {
      request: e,
      response: {
        success: t.success,
        status: t.status,
        data: t.data,
        contentType: t.contentType,
        headers: t.headers,
        redirected: t.redirected
      }
    };
  } catch (e) {
    return "Fetch parse error: " + (e == null ? void 0 : e.message);
  }
}
const _t = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  clearedDeepCopy: Q,
  convertResponse: He,
  cutOutput: ze
}, Symbol.toStringTag, { value: "Module" }));
class je {
  constructor() {
    y(this, "_steps", []);
    y(this, "_start", /* @__PURE__ */ new Date());
    y(this, "_lastStep", /* @__PURE__ */ new Date());
    y(this, "_done", !1);
    this._lastStep = this._start, this._steps.push({
      message: "start",
      time: this._start.toISOString(),
      durationStart: 0
    });
  }
  step(e, r) {
    const n = /* @__PURE__ */ new Date(), i = {
      message: e,
      time: n.toISOString(),
      durationStart: n.getTime() - this._start.getTime()
    }, o = this._steps.length;
    (o >= 2 || o === 1 && e !== "done") && (this._steps[0].durationStep = 0, i.durationStep = n.getTime() - this._lastStep.getTime()), r && (i.context = r), this._steps.push(i), this._lastStep = n;
  }
  done() {
    this.step("done"), this._done = !0;
  }
  getData() {
    return this._steps;
  }
  toString() {
    return "Performance: " + JSON.stringify(this.getData()) + ` Final: ${this._done ? "Y" : "N"}`;
  }
}
var O, ie, L, Y, pe, k, S, oe, F, X, ye, $e, M, K;
let C = (O = class {
  constructor(e) {
    p(this, F);
    p(this, ye);
    p(this, M);
    p(this, ie, new Dt());
    p(this, L, {});
    p(this, Y, !1);
    p(this, pe, 0);
    p(this, k, 0);
    p(this, S, []);
    p(this, oe, void 0);
    if (this.addTransports(e).using <= 0)
      throw new Error("No transports defined.");
  }
  getVuePluginInstaller() {
    return {
      install: (r) => {
        if (!r.version.startsWith("3."))
          throw new Error("Vue 3 is required");
        r.config.globalProperties.$cglogger = this;
      }
    };
  }
  getTransports() {
    return c(this, S);
  }
  addTransports(e) {
    const r = Array.isArray(e) ? e : [e];
    let n = 0;
    for (const i of r)
      c(this, S).some((o) => o.getId() === i.getId()) || (i.setAppTicker(c(this, oe)), c(this, S).push(i), n++);
    return g(this, k, c(this, S).length), { added: n, using: c(this, k) };
  }
  removeTransports(e) {
    const r = c(this, k), n = c(this, S).filter((o) => e.getId() !== o.getId()), i = n.length;
    return i > 0 ? (g(this, S, n), g(this, k, i), {
      removed: r - i,
      using: i
    }) : {
      removed: 0,
      using: r
    };
  }
  async info(e, r) {
    return w(this, M, K).call(this, {
      message: e,
      level: O.LEVELS.INFO,
      ...w(this, F, X).call(this, r)
    });
  }
  async warn(e, r) {
    return w(this, M, K).call(this, {
      message: e,
      level: O.LEVELS.WARN,
      ...w(this, F, X).call(this, r)
    });
  }
  async error(e, r) {
    return w(this, M, K).call(this, {
      message: e,
      level: O.LEVELS.ERROR,
      ...w(this, F, X).call(this, r)
    });
  }
  async debug(e, r) {
    return w(this, M, K).call(this, {
      message: e,
      level: O.LEVELS.DEBUG,
      ...w(this, F, X).call(this, r)
    });
  }
  async log(e, r) {
    return w(this, M, K).call(this, {
      message: e,
      level: (r == null ? void 0 : r.level) || O.LEVELS.INFO,
      ...w(this, F, X).call(this, r)
    });
  }
  start() {
    return new je();
  }
  with(e, r) {
    return typeof r == "object" ? !1 : r == null ? this.removeWith(e) : (c(this, L)[e] = r, g(this, Y, Object.keys(c(this, L)).length > 0), !0);
  }
  removeWith(e) {
    return e in c(this, L) ? (delete c(this, L)[e], g(this, Y, Object.keys(c(this, L)).length > 0), !0) : !1;
  }
  withPayment(e) {
    return this.with("payment", e);
  }
  withInstance(e) {
    return this.with("instance", e);
  }
  withAppTicker(e) {
    return g(this, oe, e), c(this, S).forEach((r) => r.setAppTicker(e)), !0;
  }
  async withPerformance(e, r, n) {
    try {
      const i = new je();
      let o = null;
      const s = async () => {
        i.done();
        const a = n || { level: "INFO" };
        a.level = a.level || "INFO", a.context = a.context || {}, a.context.performance = i.getData();
        const u = await this.log(e, a);
        return o && o(u), u;
      };
      if (await r({
        step: i.step.bind(i),
        getData: i.getData.bind(i)
      }, s), r.length === 2)
        return new Promise((a) => {
          o = a;
        });
      if (r.length === 1)
        return await s();
    } catch {
    }
    return !1;
  }
}, ie = new WeakMap(), L = new WeakMap(), Y = new WeakMap(), pe = new WeakMap(), k = new WeakMap(), S = new WeakMap(), oe = new WeakMap(), F = new WeakSet(), X = function(e) {
  const { code: r, context: n, onlyLocal: i, dispatchNow: o, ignoreLimitCheck: s, date: a } = e ?? {}, u = {
    onlyLocal: i ?? !1,
    dispatchNow: o ?? !1,
    ignoreLimitCheck: s ?? !1
  };
  return r && (u.code = Number(r)), n && (u.context = Q(n)), a && (u.date = new Date(a)), c(this, Y) && (u.details = Q(c(this, L), { maxDeep: 1 })), u;
}, ye = new WeakSet(), $e = async function() {
  try {
    await c(this, ie).acquireAsync();
    try {
      return ++Ie(this, pe)._;
    } catch {
      return;
    } finally {
      c(this, ie).release();
    }
  } catch {
    return;
  }
}, M = new WeakSet(), K = async function(e) {
  try {
    if (!e.message || !e.level)
      return !1;
    typeof e.level == "string" && (e.level = e.level.toUpperCase());
    let r = !1, n = !1;
    for (let o = 0; o < c(this, k); o++) {
      const s = c(this, S)[o];
      if (s.canHandleLevel(e.level) && (r = !0, !s.isLocal())) {
        n = !0;
        break;
      }
    }
    if (!r)
      return !1;
    if (n || (e.onlyLocal = !0), e.level === "ERR" && (e.level = "ERROR"), !e.onlyLocal) {
      const o = await w(this, ye, $e).call(this), s = e.date || /* @__PURE__ */ new Date();
      e.order = o, e.date = s;
      const a = e.details;
      e.details = {
        ...Q(a),
        order: o
      };
    }
    let i = 0;
    for (let o = 0; o < c(this, k); o++) {
      const s = c(this, S)[o];
      s.canHandleLevel(e.level) && (!e.onlyLocal || e.onlyLocal && s.isLocal()) && (s.handle(e, c(this, L).payment), i++);
    }
    return i > 0;
  } catch (r) {
    return console.error("Logger error", r), !1;
  }
}, y(O, "LEVELS", {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR"
}), O);
var we, J, Z, se;
const ke = class ke {
  constructor(e, r) {
    p(this, we, qe());
    p(this, J, {});
    p(this, Z, {});
    p(this, se, void 0);
    if (this.constructor === ke)
      throw new Error("Instance of Abstract Class cannot be created");
    g(this, Z, e), g(this, J, r);
  }
  getId() {
    return c(this, we);
  }
  setConfig(e, r) {
    const n = {};
    return n[e] = r, this.applyConfig(n) === 1;
  }
  getConfig(e) {
    if (e in c(this, J))
      return c(this, J)[e];
  }
  setAppTicker(e) {
    g(this, se, e);
  }
  getAppTicker() {
    return c(this, se);
  }
  handle(e, r) {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  getType() {
    throw new Error("TransportBase abstract method 'handle' has no implementation");
  }
  isLocal() {
    throw new Error("TransportBase abstract method 'isLocal' has no implementation");
  }
  canIgnoreLimit() {
    return !1;
  }
  canDispatchNow() {
    return !1;
  }
  canHandleLevel(e) {
    return c(this, J)[e.toLowerCase()] === !0;
  }
  applyConfig(e) {
    if (!e || typeof e != "object")
      return 0;
    let r = 0;
    for (const n in e) {
      const i = c(this, Z)[n], o = Array.isArray(i);
      n in c(this, Z) && (!o && typeof e[n] === i || o && i.includes(e[n])) && (c(this, J)[n] = e[n], r++);
    }
    return r;
  }
  hole(e) {
    (e == null ? void 0 : e.catch) === "function" && e.catch((r) => {
      console.warn("Failed to send logs to Comgate server.\\nDetail:", r);
    });
  }
};
we = new WeakMap(), J = new WeakMap(), Z = new WeakMap(), se = new WeakMap();
let ne = ke;
class Ge extends ne {
  constructor(e) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      prefix: "string"
    }, {
      debug: !0,
      info: !0,
      warn: !0,
      error: !0,
      prefix: ""
    }), super.applyConfig(e);
  }
  handle(e, r) {
    var n, i, o;
    try {
      let s = e.level;
      e.onlyLocal && (s = "LOCAL " + s), e.level === C.LEVELS.DEBUG && (s = ` ${s} `);
      const a = `%c${this.getConfig("prefix")}${s}`;
      let u = "";
      e.order && (u += ` (${e.order})`), e.code && (u += ` <${e.code}>`), (r || (n = e.details) != null && n.payment) && (u += ` [${r || ((i = e.details) == null ? void 0 : i.payment)}]`), (o = e.details) != null && o.instance && (u += ` {${e.details.instance}}`), u += ":";
      const f = [u.trim(), e.message];
      switch (e.context && (f.push("\\nContext:"), f.push(e.context)), e.level) {
        case C.LEVELS.DEBUG:
          console.log(a, "color:#fff;background:#444;font-weight: bold", ...f);
          break;
        case C.LEVELS.INFO:
          console.log(a, "color:#0099e5;font-weight: bold", ...f);
          break;
        case C.LEVELS.WARN:
          console.log(a, "color:#FFA500;font-weight: bold", ...f);
          break;
        case C.LEVELS.ERROR:
          console.log(a, "color:#ff221e;font-weight: bold", ...f);
          break;
        default:
          return console.log("Invalid level.", e), !1;
      }
      return !0;
    } catch {
    }
    return !1;
  }
  isLocal() {
    return !0;
  }
  canIgnoreLimit() {
    return !0;
  }
  getType() {
    return "ConsoleTransport";
  }
}
const Oe = typeof Buffer == "function";
typeof TextDecoder == "function" && new TextDecoder();
const Ue = typeof TextEncoder == "function" ? new TextEncoder() : void 0, kt = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", te = Array.prototype.slice.call(kt);
((t) => {
  let e = {};
  return t.forEach((r, n) => e[r] = n), e;
})(te);
const R = String.fromCharCode.bind(String);
typeof Uint8Array.from == "function" && Uint8Array.from.bind(Uint8Array);
const Ct = (t) => t.replace(/=/g, "").replace(/[+\/]/g, (e) => e == "+" ? "-" : "_"), It = (t) => {
  let e, r, n, i, o = "";
  const s = t.length % 3;
  for (let a = 0; a < t.length; ) {
    if ((r = t.charCodeAt(a++)) > 255 || (n = t.charCodeAt(a++)) > 255 || (i = t.charCodeAt(a++)) > 255)
      throw new TypeError("invalid character found");
    e = r << 16 | n << 8 | i, o += te[e >> 18 & 63] + te[e >> 12 & 63] + te[e >> 6 & 63] + te[e & 63];
  }
  return s ? o.slice(0, s - 3) + "===".substring(s) : o;
}, Xe = typeof btoa == "function" ? (t) => btoa(t) : Oe ? (t) => Buffer.from(t, "binary").toString("base64") : It, jt = Oe ? (t) => Buffer.from(t).toString("base64") : (t) => {
  let r = [];
  for (let n = 0, i = t.length; n < i; n += 4096)
    r.push(R.apply(null, t.subarray(n, n + 4096)));
  return Xe(r.join(""));
}, Ut = (t) => {
  if (t.length < 2) {
    var e = t.charCodeAt(0);
    return e < 128 ? t : e < 2048 ? R(192 | e >>> 6) + R(128 | e & 63) : R(224 | e >>> 12 & 15) + R(128 | e >>> 6 & 63) + R(128 | e & 63);
  } else {
    var e = 65536 + (t.charCodeAt(0) - 55296) * 1024 + (t.charCodeAt(1) - 56320);
    return R(240 | e >>> 18 & 7) + R(128 | e >>> 12 & 63) + R(128 | e >>> 6 & 63) + R(128 | e & 63);
  }
}, Vt = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, Ft = (t) => t.replace(Vt, Ut), Ve = Oe ? (t) => Buffer.from(t, "utf8").toString("base64") : Ue ? (t) => jt(Ue.encode(t)) : (t) => Xe(Ft(t)), Te = (t, e = !1) => e ? Ct(Ve(t)) : Ve(t);
class j {
  constructor(e = {}) {
    $(e, "forEach") && H(e.forEach) ? e.forEach(([r, n]) => {
      this[r.toLowerCase()] = n;
    }) : U(e) && Object.entries(e).forEach(([r, n]) => {
      this[r.toLowerCase()] = n;
    });
  }
  get(e) {
    return this[e.toLowerCase()];
  }
  set(e, r) {
    this[e.toLowerCase()] = r;
  }
  static create(e = {}) {
    const r = new j(e);
    return new Proxy(r, {
      get(n, i) {
        if (typeof i == "string")
          return n.get(i);
        if ($(n, i))
          return n[i];
      },
      set(n, i, o) {
        return typeof i == "string" ? (n.set(i, o), !0) : !1;
      }
    });
  }
  [Symbol.iterator]() {
    let e = 0;
    const r = Object.keys(this);
    return {
      next: () => {
        if (e < r.length) {
          const n = r[e++];
          return { value: [n, this[n]], done: !1 };
        }
        return { done: !0 };
      }
    };
  }
}
let Mt = "https://payments.comgate.cz";
const Jt = 3e4;
async function qt(t, e = 200, r = {}, n = {}, i = {}) {
  return new Promise((o, s) => {
    Ke({
      url: t,
      params: r,
      method: "POST",
      body: n
    }, e, o, s, Bt(i));
  });
}
function Wt(t) {
  return typeof t == "string" && /^https:\/\/[a-zA-Z0-9_.-]+/.test(t);
}
function Bt(t) {
  const { maxRetries: e, repeatDelay: r, responseValidator: n, timeout: i, checkSuccessParam: o, strictMaxRetries: s, headers: a, requireJson: u } = t, f = u !== !1;
  return {
    checkSuccessParam: f ? o !== !1 : !1,
    strictMaxRetries: s !== !1,
    repeatDelay: I(r, 0, 5e3, !0, !0, 500),
    maxRetries: I(e, 0, 10, !0, !0, 1),
    responseValidator: E(n) && H(n) ? n : null,
    timeout: I(i, 0, 999999, !0, !0, Jt),
    headers: j.create(a),
    requireJson: f
  };
}
async function Fe(t, e, r) {
  try {
    if (!E(r.responseValidator))
      return { valid: !0, details: "No custom validator provided" };
    if (!H(r.responseValidator))
      return { valid: !1, details: "Provided validator is not a function" };
    let n;
    try {
      n = await r.responseValidator({
        response: t,
        request: e,
        customConfig: r,
        json: t == null ? void 0 : t.json,
        isJson: t.rawFormat === "json",
        data: t.raw,
        dataFormat: t.rawFormat
      });
    } catch (i) {
      return {
        valid: !1,
        details: "Error caught from provided validator: " + b(i)
      };
    }
    return n === !0 ? {
      valid: !0,
      details: "Custom validation passed"
    } : typeof n == "string" ? {
      valid: !1,
      details: n
    } : {
      valid: !1,
      details: "Detail not provided"
    };
  } catch (n) {
    return {
      valid: !1,
      details: "System error caught: " + b(n)
    };
  }
}
async function Ke(t, e = 200, r, n, i, o = !1, s = !1, a = 0, u = 0) {
  try {
    if (!t.url)
      throw new P("Missing URL");
    const f = Ye(t.url, Mt);
    t.url = f.url, t.baseUrl = f.base, t.headers || (t.headers = j.create()), t.headers["X-Requested-With"] = "XMLHttpRequest", t.headers["X-Cg-Origin"] = window.location.origin, t.timeout = i.timeout, Qe(t, i).then(async (d) => {
      const l = {
        message: "Unknown error",
        errorCode: "UNKNOWN-ERROR",
        valid: !1,
        response: d
      };
      if (Array.isArray(e) && (e.length !== 2 || e[0] > e[1]) && console.warn("Invalid successCode array " + JSON.stringify(e) + " the first number must be lower or equal than the second one"), Array.isArray(e) ? d.status && d.status >= e[0] && d.status && d.status <= e[1] : d.status && d.status === e) {
        if (i.requireJson && d.status !== 204) {
          if (d.rawFormat === "json") {
            if (i.checkSuccessParam && Se(d.json, "success") === !0 || !i.checkSuccessParam) {
              const m = await Fe(d, t, i);
              if (m.valid) {
                r(xe(d, t, i, { successCode: e, retryCount: u, networkErrorCount: a }));
                return;
              }
              return l.message = "Invalid (JSON) by custom validator: " + m.details, l.errorCode = "INVALID-JSON-BY-VALIDATOR", Promise.reject(l);
            }
            return l.message = "Incorrect return data. Expected JSON with 'success=true' param.", l.errorCode = "INVALID-JSON-SUCCESS-PARAM", Promise.reject(l);
          }
          return l.message = "Incorrect return data format. Expected JSON.", l.errorCode = "INVALID-DATA-JSON-EXPECTED", Promise.reject(l);
        }
        const v = await Fe(d, t, i);
        if (v.valid) {
          r(xe(d, t, i, { successCode: e, retryCount: u, networkErrorCount: a }));
          return;
        }
        return l.message = "Invalid (non-JSON) by custom validator: " + v.details, l.errorCode = "INVALID-NONJSON-BY-VALIDATOR", Promise.reject(l);
      }
      return l.message = "Invalid response status code.", l.errorCode = "INVALID-RESPONSE-CODE", Promise.reject(l);
    }).catch((d) => {
      const l = (d == null ? void 0 : d.code) ?? 0, h = l === DOMException.TIMEOUT_ERR, v = l === DOMException.NETWORK_ERR;
      let m, T;
      re(d, "valid") === !1 ? T = b(d, "Invalid response validation") : T = b(d);
      const G = re(d, "response");
      G ? (m = G, delete d.response) : m = d;
      let N = a;
      if (h && !o || v && !s ? N += 1 : N = 0, !i.strictMaxRetries && N <= 2 || i.maxRetries > u) {
        let D = u + 1;
        (h || v) && N < 1 && !i.strictMaxRetries && (D = u), setTimeout(() => {
          Ke(t, e, r, n, i, h, v, N, D);
        }, i.repeatDelay);
        return;
      }
      n(xe(m, t, i, {
        eCode: l,
        eMessage: T,
        successCode: e,
        networkErrorCount: N,
        retryCount: u
      }));
    });
  } catch (f) {
    n({
      error: new P("System error: " + b(f))
    });
  }
}
function xe(t, e, r, n) {
  const i = U(t) ? t : {}, o = {
    json: i.json,
    raw: i.raw,
    rawFormat: i.rawFormat,
    contentType: i.contentType,
    status: i.status,
    success: i.success,
    headers: i.headers,
    request: e,
    details: {
      checkSuccessParam: r.checkSuccessParam,
      networkErrorCount: n.networkErrorCount,
      retries: n.retryCount,
      maxRetries: r.maxRetries,
      redirected: i.redirected,
      expectedStatusCode: n.successCode,
      url: i.url
    }
  };
  return (E(n.eCode) || E(n.eMessage)) && (o.error = {
    code: n.eCode,
    message: n.eMessage
  }), o;
}
async function Qe(t, e) {
  const r = $t(t.url, t.params), n = {
    method: t.method,
    headers: t.headers,
    signal: zt(e.timeout),
    mode: "cors",
    redirect: "follow"
  };
  ["POST", "PUT", "PATCH"].includes(t.method) && t.body && (n.body = JSON.stringify(t.body), n.headers["Content-Type"] = "application/json");
  const i = await fetch(r, n), { json: o, raw: s, type: a, contentType: u, error: f } = await Ht(i, e), d = {
    json: o,
    raw: s,
    rawFormat: a,
    contentType: u,
    status: i.status,
    redirected: i.redirected,
    success: i.ok,
    headers: j.create(i.headers),
    url: i.url,
    request: n
  };
  return ["error", "no-response"].includes(a) ? Promise.reject({
    response: d,
    error: f
  }) : d;
}
function zt(t) {
  if (typeof AbortController < "u") {
    const e = new AbortController();
    return setTimeout(() => e.abort(), t), e.signal;
  }
  return typeof AbortSignal < "u" ? AbortSignal.timeout(t) : null;
}
async function Ht(t, e) {
  var n;
  if (!t)
    return { type: "no-response", json: void 0, raw: void 0, contentType: void 0, error: void 0 };
  const r = (n = t.headers.get("Content-Type")) == null ? void 0 : n.toLowerCase();
  if (t.status === 204)
    return { type: "no-content", json: void 0, raw: void 0, contentType: r, error: void 0 };
  try {
    const i = await t.text();
    if ((r == null ? void 0 : r.includes("application/json")) && i && e.requireJson)
      try {
        return { type: "json", json: JSON.parse(i), raw: i, contentType: r };
      } catch (s) {
        return { type: "error", json: void 0, raw: i, contentType: r, error: s };
      }
    else
      return { type: "text", json: void 0, raw: i, contentType: r };
  } catch (i) {
    return { type: "error", json: void 0, raw: void 0, contentType: r, error: i };
  }
}
function $t(t, e) {
  const r = new URL(t);
  return e && typeof e == "object" && Object.keys(e).forEach((n) => r.searchParams.append(n, e[n])), r.toString();
}
function Ye(t, e) {
  const r = t;
  return r.startsWith("/") && e ? {
    url: e + r,
    base: e
  } : {
    url: r,
    base: void 0
  };
}
var A, q, W, B, ee, ae, Re, le, Le, ge, et, me, tt;
class Ze extends ne {
  constructor(r) {
    super({
      debug: "boolean",
      info: "boolean",
      warn: "boolean",
      error: "boolean",
      debounce: "number",
      maxWait: "number",
      maxStack: "number"
    }, {
      debug: !1,
      info: !0,
      warn: !0,
      error: !0,
      debounce: 2e3,
      maxWait: 1e4,
      maxStack: 20
    });
    p(this, ae);
    p(this, le);
    p(this, ge);
    p(this, me);
    p(this, A, []);
    p(this, q, void 0);
    p(this, W, void 0);
    p(this, B, {
      aborted: !1,
      time: (/* @__PURE__ */ new Date()).getTime(),
      count: 0
    });
    p(this, ee, !1);
    g(this, A, []), super.applyConfig(r), w(this, ae, Re).call(this);
  }
  getQueueSize() {
    return c(this, A).length;
  }
  handle(r, n) {
    if ((r.ignoreLimitCheck || [C.LEVELS.WARN, C.LEVELS.ERROR].includes(r.level)) && g(this, ee, !0), !w(this, me, tt).call(this))
      return !1;
    n && g(this, W, n);
    const i = {
      message: r.message,
      level: r.level,
      code: r.code,
      order: r.order,
      date: r.date ?? /* @__PURE__ */ new Date()
    };
    if (r.context && (i.context = Q(r.context)), r.details && (i.details = Q(r.details)), c(this, A).push(i), r.dispatchNow || c(this, A).length >= this.getConfig("maxStack"))
      this.hole(this.dispatchNow());
    else if (c(this, q))
      c(this, q).call(this);
    else
      return !1;
    return !0;
  }
  dispatchNow() {
    var r;
    return (r = c(this, q)) == null || r.cancel(), w(this, le, Le).call(this);
  }
  applyConfig(r) {
    const n = super.applyConfig(r);
    if (!r || n <= 0)
      return 0;
    const i = Object.keys(r);
    return i.some((o) => ["debounce", "maxWait"].includes(o)) && w(this, ae, Re).call(this), i.includes("maxStack") && c(this, A).length > this.getConfig("maxStack") && this.hole(this.dispatchNow()), n;
  }
  isLocal() {
    return !1;
  }
  canIgnoreLimit() {
    return !0;
  }
  canDispatchNow() {
    return !0;
  }
  getType() {
    return "ServerTransport";
  }
}
A = new WeakMap(), q = new WeakMap(), W = new WeakMap(), B = new WeakMap(), ee = new WeakMap(), ae = new WeakSet(), Re = function() {
  var r;
  (r = c(this, q)) == null || r.flush(), g(this, q, pt(() => this.hole(w(this, le, Le).call(this)), this.getConfig("debounce"), { maxWait: this.getConfig("maxWait") }));
}, le = new WeakSet(), Le = async function() {
  const r = c(this, A);
  if (g(this, A, []), r.length <= 0)
    return Promise.resolve({
      invalid: !1,
      count: 0,
      loggerEnabled: this.getConfig("enabled"),
      stackSize: r.length,
      server: null,
      error: null
    });
  const n = c(this, ee);
  g(this, ee, !1);
  const i = w(this, ge, et).call(this, r);
  return qt("/checkout/journal", 204, {
    ignoreLimitCheck: n ? 1 : 0,
    ...c(this, W) && { id: c(this, W) }
  }, i, {
    maxRetries: 1,
    repeatDelay: 500,
    checkSuccessParam: !1
  }).then(() => Promise.resolve({
    invalid: !1,
    count: r.length
  })).catch((o) => Promise.resolve({
    invalid: !0,
    count: r.length,
    stackSize: this.getConfig("maxStack"),
    error: o
  }));
}, ge = new WeakSet(), et = function(r) {
  const n = {
    ...c(this, W) && { transId: c(this, W) },
    encoded: !0,
    logs: []
  }, i = this.getAppTicker();
  return i && (n.appTicker = i), n.logs = r.map((o) => {
    const { level: s, date: a, code: u, order: f, message: d, context: l, details: h } = o;
    return {
      level: s,
      dt: a.toISOString(),
      ...u && { code: u },
      ...f && { order: f },
      message: Te(d),
      ...l && { context: Te(JSON.stringify(l)) },
      ...h && { details: Te(JSON.stringify(h)) }
    };
  }), n;
}, me = new WeakSet(), tt = function() {
  const { aborted: r, time: n, count: i } = c(this, B);
  if (r)
    return !1;
  const o = window.Cypress ? 45 : 5, s = (/* @__PURE__ */ new Date()).getTime();
  return i > o && s - n < 1500 ? (c(this, B).aborted = !0, !1) : (s - n < 1500 ? ++c(this, B).count : (c(this, B).count = 0, c(this, B).time = (/* @__PURE__ */ new Date()).getTime()), !0);
};
function rt(t) {
  return new C(t);
}
const fe = rt([new Ge(), new Ze()]), Gt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BaseTransport: ne,
  ConsoleTransport: Ge,
  Logger: C,
  LoggerInstance: fe,
  ServerTransport: Ze,
  createLogger: rt,
  default: fe
}, Symbol.toStringTag, { value: "Module" }));
class z {
  static validateUrl(e) {
    return /^http(|s):\/\/.*/i.test(e);
  }
  static redirectGet(e, r = "self", ...n) {
    if (!e || typeof e != "string" || !z.validateUrl(e))
      throw new P("Cannot GET redirect to invalid URL address.", n);
    const i = z.getRedirectWindow(r);
    try {
      i.location.href = e;
    } catch (o) {
      fe.error("Cannot redirect to URL. Use failsafe.", { context: { url: e, redirectMode: r, error: o } }), window.location.href = e;
    }
  }
  static redirectPost(e, r, n, i = "self", ...o) {
    if (!e || typeof e != "string" || !z.validateUrl(e))
      throw new P("Cannot POST redirect to invalid URL address.", o);
    const s = document.createElement("form");
    s.action = e, s.method = "POST", s.enctype = "multipart/form-data", s.target = z.getFormEscapeTarget(i), typeof document.body.append == "function" ? document.body.append(s) : document.body.appendChild(s);
    for (const a in r) {
      const u = document.createElement("input");
      u.type = "hidden", u.name = a, u.name = a, u.value = r[a], s.append(u);
    }
    n && (s.enctype = n, s.encoding = n), s.submit();
  }
  static redirectFormPost(e, r = "self") {
    if (!e || typeof e != "string")
      throw new P("Cannot redirect to invalid form.", { formDef: e, redirectMode: r });
    const n = document.createElement("div");
    n.innerHTML = e;
    const i = n.firstChild;
    if (i && i instanceof HTMLFormElement)
      i.target = z.getFormEscapeTarget(r), document.body.append(i), i.submit();
    else
      throw new P("Cannot get form from virtual DIV.", { formDef: e, redirectMode: r });
  }
  static getFormEscapeTarget(e = "self") {
    switch (e) {
      case "parent":
        return "_parent";
      case "top":
        return "_top";
      case "self":
      default:
        return "_self";
    }
  }
  static getRedirectWindow(e = "self") {
    return e === "top" && window.top ? window.top : e === "parent" && window.parent ? window.parent : e === "self" && window.self ? window.self : (fe.warn("Unknown redirect mode. Redirecting to self window.", { context: { redirectMode: e } }), window);
  }
}
const ve = "PENDING", Pe = "PAID", Ne = "AUTHORIZED", De = "CANCELLED", nt = "CANCELED", it = [Pe, Ne, ve, De, nt], ot = [Pe], Xt = [ve], st = [Ne], at = [De, nt];
function ce(t) {
  return t == null ? void 0 : t.toString().toUpperCase().trim();
}
function Kt(t) {
  return it.includes(ce(t));
}
function lt(t) {
  return ot.includes(ce(t));
}
function ct(t) {
  return st.includes(ce(t));
}
function ut(t) {
  return at.includes(ce(t));
}
function dt(t) {
  return lt(t) || ct(t);
}
function Qt(t) {
  return !he(t);
}
function he(t) {
  return dt(t) || ut(t);
}
function Yt(t, e) {
  return he(t) ? t : he(e) ? e : ve;
}
const Zt = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  STATUS_AUTHORIZED: Ne,
  STATUS_CANCELLED: De,
  STATUS_PAID: Pe,
  STATUS_PENDING: ve,
  authorizedState: st,
  cancelledState: at,
  getFinalOrPending: Yt,
  isAuthorized: ct,
  isCancelled: ut,
  isFinal: he,
  isPaid: lt,
  isPaidOrAuthorized: dt,
  isPending: Qt,
  isValidStatus: Kt,
  normalizeStatus: ce,
  paidState: ot,
  paymentStates: it,
  pendingState: Xt
}, Symbol.toStringTag, { value: "Module" })), _e = /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/gim;
function er(t) {
  return typeof t == "string" ? _e.test(t) : !1;
}
function tr(t = window.location.toString()) {
  var e;
  try {
    const r = _e.exec(t), n = (e = r == null ? void 0 : r.groups) == null ? void 0 : e.agmo;
    if (n)
      return n;
  } catch (r) {
    console.error(r);
  }
  return null;
}
const rr = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getPaymentId: tr,
  isTransIdValid: er,
  transIdRegex: _e
}, Symbol.toStringTag, { value: "Module" })), nr = "https://payments.comgate.cz", ir = 3e4;
class or {
  constructor() {
    y(this, "baseDomain", nr);
    y(this, "defaultTimeOut", ir);
    y(this, "get", (e, r = 200, n = {}, i = {}) => new Promise((o, s) => {
      this.makeRequest({
        url: e,
        params: n,
        method: "GET"
      }, r, o, s, this.expandConfig(i));
    }));
    y(this, "post", (e, r = 200, n = {}, i = {}, o = {}) => new Promise((s, a) => {
      this.makeRequest({
        url: e,
        params: n,
        method: "POST",
        body: i
      }, r, s, a, this.expandConfig(o));
    }));
    y(this, "changeBaseDomain", (e) => {
      let r = !0, n = e.trim();
      return n.endsWith("/") && (n = n.slice(0, -1)), n.startsWith("http://") && (!window.Cypress || location.hostname !== "localhost") && (n = n.replace("http://", "https://"), console.warn(`Checkout SDK: Changing HTTP of base domain to HTTPS: '${n}'`)), Wt(n) && (this.baseDomain = n, r = !1), r && console.warn(`Checkout SDK: Invalid base domain: '${n}'. Using default domain: '${this.baseDomain}'`), !r;
    });
    y(this, "expandConfig", (e) => {
      const { maxRetries: r, repeatDelay: n, responseValidator: i, timeout: o, checkSuccessParam: s, strictMaxRetries: a, headers: u, requireJson: f } = e, d = f !== !1;
      return {
        checkSuccessParam: d ? s !== !1 : !1,
        strictMaxRetries: a !== !1,
        repeatDelay: I(n, 0, 5e3, !0, !0, 500),
        maxRetries: I(r, 0, 10, !0, !0, 1),
        responseValidator: E(i) && H(i) ? i : null,
        timeout: I(o, 0, 999999, !0, !0, this.defaultTimeOut),
        headers: j.create(u),
        requireJson: d
      };
    });
    y(this, "contentCustomValidation", async (e, r, n) => {
      try {
        if (!E(n.responseValidator))
          return { valid: !0, details: "No custom validator provided" };
        if (!H(n.responseValidator))
          return { valid: !1, details: "Provided validator is not a function" };
        let i;
        try {
          i = await n.responseValidator({
            response: e,
            request: r,
            customConfig: n,
            json: e == null ? void 0 : e.json,
            isJson: e.rawFormat === "json",
            data: e.raw,
            dataFormat: e.rawFormat
          });
        } catch (o) {
          return {
            valid: !1,
            details: "Error caught from provided validator: " + b(o)
          };
        }
        return i === !0 ? {
          valid: !0,
          details: "Custom validation passed"
        } : typeof i == "string" ? {
          valid: !1,
          details: i
        } : {
          valid: !1,
          details: "Detail not provided"
        };
      } catch (i) {
        return {
          valid: !1,
          details: "System error caught: " + b(i)
        };
      }
    });
    y(this, "makeRequest", async (e, r = 200, n, i, o, s = !1, a = !1, u = 0, f = 0) => {
      try {
        if (e.url === void 0)
          throw new P("Missing URL");
        const d = Ye(e.url, this.baseDomain);
        e.url = d.url, e.baseUrl = d.base, e.headers || (e.headers = j.create()), e.headers["X-Requested-With"] = "XMLHttpRequest", e.headers["X-Cg-Origin"] = window.location.origin, e.timeout = o.timeout, Qe(e, o).then(async (l) => {
          const h = {
            message: "Unknown error",
            errorCode: "UNKNOWN-ERROR",
            valid: !1,
            response: l
          };
          if (Array.isArray(r) && (r.length !== 2 || r[0] > r[1]) && console.warn("Invalid successCode array " + JSON.stringify(r) + " the first number must be lower or equal than the second one"), Array.isArray(r) ? l.status && l.status >= r[0] && l.status && l.status <= r[1] : l.status && l.status === r) {
            if (o.requireJson && l.status !== 204) {
              if (l.rawFormat === "json") {
                if (o.checkSuccessParam && Se(l.json, "success") === !0 || !o.checkSuccessParam) {
                  const T = await this.contentCustomValidation(l, e, o);
                  if (T.valid) {
                    n(this.buildResponseMessage(l, e, o, { successCode: r, retryCount: f, networkErrorCount: u }));
                    return;
                  }
                  return h.message = "Invalid (JSON) by custom validator: " + T.details, h.errorCode = "INVALID-JSON-BY-VALIDATOR", Promise.reject(h);
                }
                return h.message = "Incorrect return data. Expected JSON with 'success=true' param.", h.errorCode = "INVALID-JSON-SUCCESS-PARAM", Promise.reject(h);
              }
              return h.message = "Incorrect return data format. Expected JSON.", h.errorCode = "INVALID-DATA-JSON-EXPECTED", Promise.reject(h);
            }
            const m = await this.contentCustomValidation(l, e, o);
            if (m.valid) {
              n(this.buildResponseMessage(l, e, o, { successCode: r, retryCount: f, networkErrorCount: u }));
              return;
            }
            return h.message = "Invalid (non-JSON) by custom validator: " + m.details, h.errorCode = "INVALID-NONJSON-BY-VALIDATOR", Promise.reject(h);
          }
          return h.message = "Invalid response status code.", h.errorCode = "INVALID-RESPONSE-CODE", Promise.reject(h);
        }).catch((l) => {
          const h = (l == null ? void 0 : l.code) ?? 0, v = h === DOMException.TIMEOUT_ERR, m = h === DOMException.NETWORK_ERR;
          let T, G;
          re(l, "valid") === !1 ? G = b(l, "Invalid response validation") : G = b(l);
          const N = re(l, "response");
          N ? (T = N, delete l.response) : T = l;
          let D = u;
          if (v && !s || m && !a ? D += 1 : D = 0, !o.strictMaxRetries && D <= 2 || o.maxRetries > f) {
            let Ce = f + 1;
            (v || m) && D < 1 && !o.strictMaxRetries && (Ce = f), setTimeout(() => {
              this.makeRequest(e, r, n, i, o, v, m, D, Ce);
            }, o.repeatDelay);
            return;
          }
          i(this.buildResponseMessage(T, e, o, {
            eCode: h,
            eMessage: G,
            successCode: r,
            networkErrorCount: D,
            retryCount: f
          }));
        });
      } catch (d) {
        i({
          error: new P("System error: " + b(d))
        });
      }
    });
    y(this, "buildResponseMessage", (e, r, n, i) => {
      const o = U(e) ? e : {}, s = {
        json: o.json,
        raw: o.raw,
        rawFormat: o.rawFormat,
        contentType: o.contentType,
        status: o.status,
        success: o.success,
        headers: o.headers,
        request: r,
        details: {
          checkSuccessParam: n.checkSuccessParam,
          networkErrorCount: i.networkErrorCount,
          retries: i.retryCount,
          maxRetries: n.maxRetries,
          redirected: o.redirected,
          expectedStatusCode: i.successCode,
          url: o.url
        }
      };
      return (E(i.eCode) || E(i.eMessage)) && (s.error = {
        code: i.eCode,
        message: i.eMessage
      }), s;
    });
    y(this, "makeRawRequest", async (e, r) => {
      const n = this.buildUrlWithQuery(e.url, e.params), i = {
        method: e.method,
        headers: e.headers,
        signal: this.createTimeoutSignal(r.timeout),
        mode: "cors",
        redirect: "follow"
      };
      ["POST", "PUT", "PATCH"].includes(e.method) && e.body && (i.body = JSON.stringify(e.body), i.headers["Content-Type"] = "application/json");
      const o = await fetch(n, i), { json: s, raw: a, type: u, contentType: f, error: d } = await this.decodeBody(o, r), l = {
        json: s,
        raw: a,
        rawFormat: u,
        contentType: f,
        status: o.status,
        redirected: o.redirected,
        success: o.ok,
        headers: j.create(o.headers),
        url: o.url,
        request: i
      };
      return ["error", "no-response"].includes(u) ? Promise.reject({
        response: l,
        error: d
      }) : l;
    });
    y(this, "createTimeoutSignal", (e) => {
      if (typeof AbortController < "u") {
        const r = new AbortController();
        return setTimeout(() => r.abort(), e), r.signal;
      }
      return typeof AbortSignal < "u" ? AbortSignal.timeout(e) : null;
    });
    y(this, "decodeBody", async (e, r) => {
      var i;
      if (!e)
        return { type: "no-response", json: void 0, raw: void 0, contentType: void 0, error: void 0 };
      const n = (i = e.headers.get("Content-Type")) == null ? void 0 : i.toLowerCase();
      if (e.status === 204)
        return { type: "no-content", json: void 0, raw: void 0, contentType: n, error: void 0 };
      try {
        const o = await e.text();
        if ((n == null ? void 0 : n.includes("application/json")) && o && r.requireJson)
          try {
            return { type: "json", json: JSON.parse(o), raw: o, contentType: n };
          } catch (a) {
            return { type: "error", json: void 0, raw: o, contentType: n, error: a };
          }
        else
          return { type: "text", json: void 0, raw: o, contentType: n };
      } catch (o) {
        return { type: "error", json: void 0, raw: void 0, contentType: n, error: o };
      }
    });
    y(this, "buildUrlWithQuery", (e, r) => {
      const n = new URL(e);
      return r && typeof r == "object" && Object.keys(r).forEach((i) => n.searchParams.append(i, r[i])), n.toString();
    });
    y(this, "expandUrl", (e, r) => {
      const n = e;
      return n.startsWith("/") && r ? {
        url: r + n,
        base: r
      } : {
        url: n,
        base: void 0
      };
    });
  }
}
const lr = {
  Helpers: Pt,
  RequestService: or,
  Logger: Gt,
  CgError: P,
  Sanitizer: _t,
  Redirector: z,
  StatusUtils: Zt,
  TransactionUtils: rr,
  SmartObject: j
};
export {
  P as CgError,
  Pt as Helpers,
  Gt as Logger,
  z as Redirector,
  or as RequestService,
  _t as Sanitizer,
  j as SmartObject,
  Zt as StatusUtils,
  rr as TransactionUtils,
  lr as default
};
